<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <p>{{ username }} recording time against {{ jobLine.description }}</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-5">
        <label>Hours</label>
        <select v-model="timeRecord.hours">
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
        </select>
      </div>
      <div class="cell small-12 medium-5">
        <label>Minutes</label>
        <select v-model="timeRecord.minutes">
          <option value="0">0</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="45">45</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <label>Work completed</label>
        <textarea rows="4" v-model="timeRecord.work_completed"></textarea>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <button @click="saveTime" class="button">Save</button>
        &nbsp;
        <button @click="$emit('hideRecordTime')" class="button red">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'JobRecordTime',
  props: [
    'jobLineId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
    username() {
      return this.$store.state.username;
    },
  },
  data() {
    return {
      jobLine: {},
      timeRecord: {
        hours: 0,
        minutes: 0,
        work_completed: '',
      },
    };
  },
  methods: {
    saveTime() {
      const postData = {};
      postData.timeRecord = this.timeRecord;
      postData.timeRecord.job_line_id = this.jobLine.id;
      postData.timeRecord.user_id = this.userid;
      axios.post(`/timeRecords/create/${this.jobLineId}.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('timeSaved', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLine() {
      axios.get(`/jobLines/getOne/${this.jobLineId}.json?token=${this.token}`)
        .then((response) => {
          this.jobLine = response.data.jobLine;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getLine();
  },
};
</script>
