<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1>View Job</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link to="/jobs/index">
          <button
            class="button expanded">All jobs</button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <button
          @click="showNotes = true"
          class="button small">Notes</button>&nbsp;
        <button
          @click="showUploads = true"
          class="button small">Upload file</button>&nbsp;
        <router-link :to="`/estimates/view/${job.estimate_id}`">
          <button
            class="button small">View estimate</button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        <h4>Details</h4>
        <p>
          Client: {{ job.client.name }}<br />
          Brand: {{ job.brand.name }}<br />
          Job Number: {{ job.job_number.job_number }}<br />
          Job Name: {{ job.job_name }}<br />
          Created by: {{ job.user.first_name }} {{ job.user.last_name }}
        </p>
      </div>
      <div class="cell small-12 medium-6">
        <h4>Notes</h4>
        <div
          v-for="(note, index) in job.job_notes"
          :key="`en_${index}`"
          v-html="note.note">
        </div>
      </div>
    </div>
    <div v-if="job.internal.length > 0" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-10">
        <h4 class="pb-2">Fees</h4>
        <table v-for="(group, index) in job.internal" :key="`g_${index}`">
          <thead>
            <tr>
              <th style="width:25%">{{ group.name }}</th>
              <th style="width:15%">Estimated Hours</th>
              <th style="width:15%">Estimated Cost</th>
              <th style="width:15%">Recorded Hours</th>
              <th style="width:15%">Actual Cost</th>
              <th style="width:15%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, lindex) in group.lines" :key="`l_${lindex}`">
              <td>{{ line.description }}</td>
              <td>{{ line.quantity }}</td>
              <td>{{ (line.cost_each * line.quantity) | currency }}</td>
              <td>{{ line.time | minutesToHours }}</td>
              <td>{{ ((line.cost_each * line.time) / 60) | currency }}</td>
              <td>
                <span
                  title="Record time"
                  class="material-symbols-outlined icon-green icon-link"
                  @click="recordTime(index,lindex)"
                  >schedule</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="job.external.length > 0" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4 class="pb-2">Expenses</h4>
        <table v-for="(group, index) in job.external" :key="`g_${index}`">
          <thead>
            <tr>
              <th style="width:60%">{{ group.name }}</th>
              <th style="width:20%">Quantity</th>
              <th class="text-right" style="width:20%">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
              <td>{{ line.description }}</td>
              <td>{{ line.quantity }}</td>
              <td class="text-right">{{ (line.cost_each * line.quantity) | currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="job.files.length > 0" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4 class="pb-2">Files</h4>
        <table>
          <tbody>
            <tr v-for="(file, index) in job.files" :key="`f_${index}`">
              <td>
                <a
                  v-if="file.mime === 'application/pdf'"
                  :href="`${baseUrl}/conversions/${file.assembly}__${file.name}.png`"
                  target="_blank">{{ file.name }}</a>
                <a
                  v-else
                  :href="`${baseUrl}/original/${file.assembly}__${file.name}.${file.ext}`"
                  target="_blank">{{ file.name }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Notes
      v-if="showNotes"
      @hideNotes="showNotes = false"
      @addNote="addNote"
      @updateNote="updateNote"
      @deleteNote="deleteNote"
      :notes="job.job_notes"
      :jobId="job.id" />
    <RecordTime
      v-if="lineToRecord > 0"
      @hideRecordTime="lineToRecord = 0"
      @timeSaved="timeSaved"
      :jobLineId="lineToRecord" />
    <Uploads
      v-if="showUploads"
      @hideUploads="showUploads = false"
      @completeUploads="completeUploads"
      :modelId="job.id"
      :model="'Jobs'"
      :clientId="job.client.id" />
    <Message
      v-if="showMessage === true"
      :messageText="message"
      :link="messageLink"
      :linkText="messageLinkText"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/messages/Message.vue';
import Notes from '@/views/jobs/components/Notes.vue';
import RecordTime from '@/views/jobs/components/RecordTime.vue';
import Uploads from '@/components/UploadFile.vue';
import axios from '../../axios';

export default {
  name: 'JobView',
  components: {
    Message,
    Notes,
    RecordTime,
    Uploads,
  },
  data() {
    return {
      baseUrl: 'https://dice-dash.s3.eu-west-2.amazonaws.com',
      groupInTime: 0,
      job: {
        client: {},
        brand: {},
        job_number: {},
        user: {},
        job_lines: [],
        internal: [],
        external: [],
        files: [],
      },
      lineInTime: 0,
      lineToRecord: 0,
      message: '',
      messageLink: false,
      messageLinkText: false,
      showAddHours: false,
      showMessage: false,
      showNotes: false,
      showUploads: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    timeSaved(response) {
      this.message = response.message;
      this.messageLink = false;
      this.messageLinkText = false;
      this.showMessage = true;
      this.job.internal[this.groupInTime].lines[this.lineInTime].time
        += (response.timeRecord.hours * 60);
      this.job.internal[this.groupInTime].lines[this.lineInTime].time
        += response.timeRecord.minutes;
      this.lineToRecord = 0;
      this.groupInTime = 0;
      this.lineInTime = 0;
    },
    recordTime(index, lindex) {
      this.groupInTime = index;
      this.lineInTime = lindex;
      this.lineToRecord = this.job.internal[index].lines[lindex].id;
    },
    completeUploads(response) {
      let message = '';
      for (let i = 0; i < response.length; i += 1) {
        this.estimate.files.push(response[i].file);
        message = `${i + 1} files saved.`;
      }
      this.showUploads = false;
      this.showMessage = true;
      this.message = message;
    },
    addNote(response) {
      this.estimate.estimate_notes.push(response.note);
      this.showNotes = false;
      this.showMessage = true;
      this.message = response.message;
    },
    updateNote(result) {
      this.estimate.estimate_notes.splice(result.index, 1, result.response.note);
      this.showNotes = false;
      this.showMessage = true;
      this.message = result.response.message;
    },
    deleteNote(response) {
      this.estimate.estimate_notes.splice(response, 1);
    },
    getJob() {
      const jobId = this.$route.params.id;
      axios.get(`/jobs/getOne/${jobId}.json?token=${this.token}`)
        .then((response) => {
          this.job = response.data.job;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getJob();
    }, 100);
  },
};
</script>
